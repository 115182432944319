import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import ChineseLayout from "../../components/ChineseLayout"
import Accordion from "../../components/Accordion"
import BrandsImage from "../../images/static-only/about-us/our-brands.jpg"
import AboutUsHeader from "../../images/static-only/about-us/About_us_header.jpg"
import AboutUsOverview from "../../images/static-only/about-us/About_us_overview.jpg"
import RandDHeader from "../../images/static-only/about-us/RandD-header.jpg"
// import Inactivity from "../../components/Inactivity"

/* Accordion Listing structure = Hero > Accordions */

class AccordionListing extends React.Component {
  componentDidMount() {
    Accordion()
    // Inactivity()
  }

  render() {
    return (
      <ChineseLayout>
        <Helmet>
          <title>Meet MJN - 关于我们</title>
        </Helmet>
        <main role="main" className="content-main -content-accordion-listing">

          <section className="hero-area -general -accordion-listing">
            <img src={AboutUsHeader} alt="A group of people gathered around a laptop" />

            <div className="hero-area__inner">
              <h1>关于我们</h1>
              <nav className="breadcrumb" role="navigation">
                  <Link to="/zh" title="首页">
                    <i className="fas fa-arrow-left"></i>
                    首页
                  </Link>
              </nav>
            </div>
          </section>

          <section className="accordion-listing">
            <nav className="breadcrumb -accordion -white" role="navigation">
              <Link to="/zh" title="首页">
                <i className="fas fa-arrow-left"></i>
                首页
              </Link>
            </nav>

            <div className="accordion">
              <div className="accordion__heading menu-button">品牌故事</div>
              <div className="accordion__content">
                <img src={AboutUsOverview} alt="Girls drinking water" />
                <div className="rich-text">
                  <h2>品牌故事</h2>
                  <p>美赞臣是英国跨国快消巨头利洁时旗下的子公司。作为领先的婴幼儿奶粉制造商，我们始终致力于研发安全可靠的高品质、创新型婴幼儿奶粉，以满足婴幼儿成长营养需求。</p>
                  <p>1895年，爱德华美赞臣先生开创了美赞臣品牌。至今，美赞臣已发展为婴幼儿营养行业的领军者。一百多年来，我们的品牌始终深受全球家长与保健专家信赖。</p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">研究开发</div>
              <div className="accordion__content">
                <img src={RandDHeader} alt="Person conducting research" />
                <div className="rich-text">
                  <h2>研究开发</h2>
                  <p>数十年的研究和学习告诉我们，从出生就营养良好的儿童更有可能过上更健康的生活，这是我们每个人、每个孩子都想要的未来。正是这种思想激励着我们每天努力发展儿科营养学。美赞臣（Mead Johnson）的研发团队将虚拟的全球网络连接起来，该网络由领先的科学专家，研究计划和数据源组成。我们的工作使我们能够进行前沿研究，然后将创新的成果能够有效地转移到含有最优质成分的产品中。创新的丰富底蕴为我们赢得了父母和医疗保健专业人员的信任。</p>

                  <h3>美赞臣专业的全球研发体系</h3>
                  <p>美赞臣拥有以北美为中心，辐射欧洲、亚洲及拉美的全球研发体系。在美国、墨西哥、中国及新加坡分别设立了美赞臣婴幼儿营养品研发中心。</p>

                  <h3>卓越的全球研发成果</h3>
                  <p>美赞臣汇聚全球科研资源，专注且持续地进行婴幼儿营养研究探索，为孩子成长的每一阶段打造卓越和优质的营养品。</p>

                  <p>我们的研究工作包括：</p>
                  <p><strong>科学研究投资</strong></p>
                  <p>我们管理全球研究计划，从涉及安全性和功效的临床研究到关于母乳中特定生物活性成分的基础研究。</p>
                  <p><strong>对家长的承诺</strong></p>
                  <p>我们深知家长对我们的信任的重要性，并且向家长保证我们每一个设施、生产线、员工和产品都遵循的严格的质量标准。此外，我们帮助父母理解喂养问题和常见的营养问题，以帮助父母和宝宝迎接人生的最佳开端。</p>
                  <p><strong>强大的伙伴关系</strong></p>
                  <p>我们与学术界，公共卫生，儿科医学，饮食学和儿童保育组织建立并发展了牢固的伙伴关系，积极合作开展影响儿童福祉的计划。</p>
                  <p><strong>教育宣传</strong></p>
                  <p>我们帮助将来自各个领域的专家聚集在一起，以支持针对医疗保健专业人员的继续教育研讨会和教材。 这项工作帮助我们及时获得全世界关于育儿的最新研究。</p>
                  <p><strong>百年严谨 品质如一</strong></p>
                  <p>美赞臣拥有全球统一的全程质量安全管理体系，涵盖从产品研发、供应商审核与管理、原料控制、生产过程、流通渠道，直到产品被使用的全过程。</p>
                  <p><strong>百年信赖 口碑卓越</strong></p>
                  <p>凭借卓越的品质 , 深受营养学家和父母的信赖。而美赞臣专注严谨的态度、持续不断的创新、对卓越孜孜不倦的追求以及对社会的关怀回馈，也帮助美赞臣屡获赞誉，树立了良好的品牌形象。</p>

                  <h3>责任理念与行动</h3>
                  <p>美赞臣对社会责任的理解，建立在对婴幼儿营养品行业的基本认知，以及对本土实践的深刻体悟基础之上，以食品安全、产品品质、消费者服务等社会责任实质性核心议题为突出之重点。</p>
                  <p>科研创新的持续投入，是品牌追求卓越品质的基础，而产品品质、食品安全则是婴幼儿乳品行业最受关注的责任议题，也是美赞臣作为婴幼儿营养品品牌最核心的责任承担，即是做"事"的准则；同时，对核心群体的关注，服务消费者则是我们品牌对"人"的关怀。这些要素，通过美赞臣良好的品牌创新、可持续化管理和优质的服务意识，得到了充分体现。同时，合规守法经营、依法纳税、环境保护、行业协作等基础性的责任议题，亦时时刻刻体现在美赞臣的日常运作当中。在承担基础责任之后，美赞臣试图更多地承担延伸的社会责任。</p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">品牌概览</div>
              <div className="accordion__content">
                <div className="rich-text">
                  <h2>品牌概览</h2>
                  
                  <p>一个多世纪以来，美赞臣（Mead Johnson）一直在开发安全，优质，创新的产品，以帮助满足婴儿和儿童的营养需求。 </p>
                  
                  <img src={BrandsImage} alt="Our brand logos" />
                  
                  <p>本网站上提供的信息旨在传达我们全球产品线的广度。 我们鼓励所有消费者咨询他们的医疗保健提供者，讨论他们为婴幼儿做出的营养选择。 法规因国家而异； 因此，并非在所有国家/地区都可以使用所有品牌。</p>
                </div>
              </div>
            </div>
          </section>

        </main>
      </ChineseLayout>
    )
  }
}

export default AccordionListing